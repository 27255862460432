.minW180 {
  min-width: 180px;
}
.minW50 {
  min-width: 50px;
}
.minW80 {
  min-width: 80px;
}
.minW120 {
  min-width: 120px;
}
.minW140 {
  min-width: 140px;
}
.minW200 {
  min-width: 200px;
}
.minW250 {
  min-width: 250px;
}
.minW280 {
  min-width: 280px;
}
.minW150 {
  min-width: 150px;
}
.minW100 {
  min-width: 100px;
}
