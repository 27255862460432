body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* esStyles */
.es_error {
  color: #bf1650;
}

.es-error-border {
  border: 2px solid red !important;
  padding: 5px;
  z-index: 100;
}

.swal2-container {
  z-index: 20000 !important;
}
.required:after {
  content: " *";
  color: red;
}
/* React toast for multiline support */
.Toastify__toast-body {
  white-space: pre-line;
}
.es_error::before {
  display: inline;
  content: "⚠ ";
}
/* esStyles */

/* eddysapata style for Reselect */
.esAllCenterRowSpaceBetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.esAllCenterRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.esAllCenterColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.es-react-select__control {
  border-width: 1px !important;
  border-color: #cbd5e0 !important;
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
}

.es-react-select__control--is-focused {
  border-color: #746df7 !important;
  box-shadow: none !important;
}

.es-react-select__indicator-separator {
  display: none;
}
.es-react-select__menu {
  z-index: 99999999 !important;
  background-color: red;
}

/* eddysapata */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-clean:not(:disabled):not(.disabled).active,
.show > .btn.btn-clean.dropdown-toggle,
.show .btn.btn-clean.btn-dropdown {
  /* background-color: #4281c1; */
}
.btn.btn-clean:not(:disabled):not(.disabled):hover:not(.btn-text),
.btn.btn-clean:not(:disabled):not(.disabled).hover:hover,
.show > .btn.btn-clean.dropdown-toggle,
.show .btn.btn-clean.btn-dropdown {
  /* background-color: #4281c1; */
}
.header-mobile-fixed .header-mobile {
  /* top: 48px; */
}

@media (max-width: 991.98px) {
  .header-menu-wrapper.header-menu-wrapper-on {
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
    left: 0;
  }
  .header-menu-wrapper {
    background: #ffffff;
    box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 75%);
  }
  .header-menu-wrapper {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
    left: -295px;
    width: 275px;
  }
  .header-mobile-fixed .topbar {
    display: none;
    top: auto;
  }
  .header-mobile-fixed .myTopHeader {
    /* display: none; */
    position: fixed;
    top: 0;
    width: 275px;
    z-index: 97;
    left: 0;
    /* overflow-x: scroll; */
  }
  .header-mobile-fixed .myTopHeader .container {
    padding-left: 0 !important;
  }
  .header-mobile-fixed .mylogo {
    display: none;
  }
}

.header-mobile-fixed .topbar {
  display: flex;
}
.header .header-top {
  background-color: white;
}
.header-tabs .nav-item .nav-link {
  color: black;
}
.header-tabs .nav-item .nav-link:hover {
  color: black;
}
.header-tabs .nav-item .nav-link.active {
  background-color: #3699ff;
  color: white;
}
.header .header-bottom {
  background-color: #3699ff;
}
.header-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: white;
}
.header-menu .menu-nav > .menu-item > .menu-link {
  margin-top: 10px;
}
.menu-item .menu-item-rel:hover .menu-text {
  background-color: #f3f6f9;
}
.menu-link:hover {
  background-color: #f3f6f9;
}
.menu-link:active {
  background-color: #f3f6f9;
}
.menu-link:hover .menu-text {
  /* color: white !important; */
}
@media (max-width: 991.98px) {
  .topbar {
    background-color: white;
  }
}


.error-boundary{
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
    display: grid;
}